<template>
  <div class="container" v-title :data-title="$t('i18n.companyManagement')">
    <div id="outer-title">{{ $t("i18n.companyManagement") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @handleEdit="handleEdit"
        @handleDelete="handleDelete"
        @getEditData="getEditData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.comLogo')"
      v-model="dialogFormVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-image :src="imgSrc"></el-image>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "CompanyMaintain",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      dialogFormVisible: false,
      imgSrc: "",
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: proxy.$defined.btnPermission("添加公司"), // 是否启用新增功能
        isEdit: proxy.$defined.btnPermission("编辑公司"), // 是否启用编辑功能
        isDelete: proxy.$defined.btnPermission("删除公司"), // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "name",
          label: "companyName",
          align: "left",
          type: "input",
          width: 400,
          search: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "address",
          label: "companyAddress",
          align: "left",
          search: true,
          type: "input",
        },
        {
          prop: "phone",
          label: "telephone",
          align: "center",
          type: "input",
        },
        {
          prop: "contact",
          label: "contactPerson",
          align: "center",
          type: "input",
        },
        {
          prop: "mobile",
          label: "mobilePhone",
          align: "center",
          type: "input",
        },
        {
          prop: "uscc",
          label: "UnifyTheSocialCreditCode",
          align: "center",
          type: "input",
        },
        {
          prop: "comLogo",
          label: "comLogo",
          align: "center",
          type: "upload",
          action: "company",
          limit: "1",
          value: [],
          tip: "只能上传jpg/png文件",
          formatter: (row) => {
            if (row.comLogo) {
              return `<img src="${proxy.$defined.imgUrl + row.comLogo}"
                style="width: 100px; height: 20px" />`;
            } else {
              return "-";
            }
          },
          method: (row) => {
            state.dialogFormVisible = true;
            state.imgSrc = proxy.$defined.imgUrl + row.comLogo;
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "export",
            icon: "el-icon-upload2",
            class: "export",
            show: true,
            type: "outer",
            method: (formInline) => {
              exportCompany(formInline);
            },
          },
          {
            id: "2",
            label: "details",
            icon: "el-icon-notebook-2",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              router.push({
                path: "/system/companyMaintaincou",
                query: { id: row.id },
              });
            },
          },
        ],
      },
      total: 0,
      loading: true,
      editData: {},
    });

    const init = async (params) => {
      state.loading = true;
      params.companyName = params.name;
      params.companyAddress = params.address;
      const { data } = await proxy.$api.system.companyPage(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const handleEdit = async (val, pages) => {
      // 新增/编辑数据
      let msg = val.id ? "edit" : "new";
      if (val.id) {
        for (let k in val) {
          if (val[k] !== undefined) {
            state.editData[k] = val[k];
          }
        }
        val = JSON.parse(JSON.stringify(state.editData));
      }
      val.id
        ? await proxy.$api.system.editCompany(val)
        : await proxy.$api.system.addCompanyPage(val);
      proxy.$defined.tip(t("i18n." + msg) + t("i18n.success"), "success");
      // 初始化 刷新
      init(pages);
    };

    const handleDelete = (row, pages) => {
      proxy.$api.system.delCompany(row.id).then(() => {
        init(pages);
        proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
      });
    };

    const getEditData = (row) => {
      state.editData = row;
    };

    const exportCompany = async (formInline) => {
      state.operates.list[0].loading = true;
      let res = await proxy.$api.system.exportCompany({
        companyAddress: formInline.companyAddress,
        companyName: formInline.companyName,
      });
      proxy.$defined.exportJsFunc(res);
      state.operates.list[0].loading = false;
    };

    return {
      ...toRefs(state),
      handleEdit,
      init,
      handleDelete,
      getEditData,
      exportCompany,
    };
  },
};
</script>
